import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../components/layout/Layout";
import "./sharing.css";
import { useParams } from "react-router-dom";
// import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
// import CircleIcon from '@mui/icons-material/Circle';
import { Switch } from "@mui/material";
function Sharing({ socketRef, user }) {
  const screenRef = useRef();
  const params = useParams();
  const [text,setText]=useState({txt:''});
  const { screens } = useSelector(
    (state) => state.Screen
  );
  const [screenString, setScreenString] = useState('')
  const [screen, setScreen] = useState({});
  useEffect(() => {
    setScreen({ ...screens?.find((s) => s?.mac === params?.id) })
  }, [params.id])
  useEffect(() => {
    //  if(socketRef){
    socketRef.current.emit('screensharing', { mac: params.id })
    socketRef.current.on('screen--sharing--success', ({ msg, screenShort }) => {
      // console.log(screenShort)
      setScreenString(screenShort)
    })
    return () => {
      socketRef.current.off('screen--sharing--success');
    }
  }, [socketRef])

  const getXYPosition = (e) => {
    const input_val = JSON.parse(screenRef?.current?.value)
    const X_scale = +input_val.width / 800;
    const Y_scale = +input_val.height / 500;
    const x = e.nativeEvent.offsetX;
    const y = e.nativeEvent.offsetY;
    socketRef.current.emit('click--position', { x: Math.floor(x * X_scale), y: Math.floor(y * Y_scale), mac: params.id })
  }

  const sendText=()=>{
       socketRef.current.emit('event--text',{mac:params.id,text:text})
       setText({txt:''})
  }
  const imgQuality=(e)=>{
    const {checked}=e.target;
    if(checked){
      socketRef.current.emit('event--quality',{mac:params.id,quality:true})
    }else{
      socketRef.current.emit('event--quality',{mac:params.id,quality:false})
    }
  }
  return (
    <>
      <input ref={screenRef} value={JSON.stringify({ height: screen?.device_details?.height, width: screen?.device_details?.width })} hidden />
      <Layout title={"Sharing"} user={user}>
        <div className="mobile--container">
          <div className="mobile--container--header">
            <button
             onClick={()=>socketRef.current.emit('window--event--click--back',{mac:params?.id})} 
            >Back</button>
            <button
             onClick={()=>socketRef.current.emit('window--event--click--down',{mac:params?.id})}
            >home</button>
            <button
            onClick={()=> socketRef.current.emit('event--open--app',{mac:params.id})}
            >Open App</button>
            <input 
            name={'txt'}
            value={text.txt}
            onChange={(e)=>setText((prev)=>({...prev,[e.target.name]:e.target.value}))}
            placeholder="Text" />
            <button onClick={sendText}>send</button>
          </div>
          <div className="mobile--container--body">
            <div
              style={{
                width: '800px',
                height: '500px',
                backgroundColor: 'white',
                textAlign: 'center',
                boxShadow: '1px 1px 2px 2px rgba(0,0,0,0.5), -1px -1px 2px 2px rgba(0,0,0,0.5)'
              }}>
              <img
                style={{ height: '100%', width: '100%' }}
                src={screenString}
                id='img-dim'
               // onMouseDown={(e) => console.log('on mouse down', e)}
                //onMouseMove={(e) => console.log('on mouse move', e)}
               // onMouseUp={(e) => console.log('on mouse up', e)}
                onClick={getXYPosition} />
            </div>
            <div className="mobile--screen--footer">
                <div className="last-update-txt">
                  <p>Last Update: <span></span></p>
                </div>
                <div className="screen-quality">
                    <Switch onChange={imgQuality}/>
                </div>
            </div>
          </div>
        </div>
        {/* <div 
        style={{
          width:'80vw',
          height:'100%',
          display:'flex',
          flexDirection:'column',
          justifyContent:'center',
          // border:'1px solid black'
        }}
        >
          <div
          style={{ width: '800px', height: '500px', backgroundColor: 'white', margin: '50px 120px', textAlign: 'center',boxShadow:'1px 1px 2px 2px rgba(0,0,0,0.5), -1px -1px 2px 2px rgba(0,0,0,0.5)' }}>
          <img
           style={{ height: '100%', width: '100%' }}
           src={screenString}
           id='img-dim'
           onMouseDown={(e)=>console.log('on mouse down',e)}
           onMouseMove={(e)=>console.log('on mouse move',e)}
           onMouseUp={(e)=>console.log('on mouse up',e)}
           onClick={getXYPosition} />
        </div>
        <div
        style={{ width: '800px', height: '20px',
        //  backgroundColor: 'white', 
         margin: '0px 120px',
         marginTop:'-40px',
          textAlign: 'center',
          // boxShadow:'1px 1px 2px 2px rgba(0,0,0,0.5), -1px -1px 2px 2px rgba(0,0,0,0.5)',
          display:'flex',
          gap:'5px',
          // flexDirection:'column',
          justifyContent:'center',
          alignItems:'center'
        }}
        >
             <button
             onClick={()=>socketRef.current.emit('window--event--click--back',{mac:params?.id})}
             className="control--btn" ><ArrowLeftIcon/></button>
             <button 
              onClick={()=>socketRef.current.emit('window--event--click--down',{mac:params?.id})}
             className="control--btn"><CircleIcon/></button>
        </div>
        </div> */}
      </Layout>


    </>
  );
}

export default Sharing;
